.app-content__container.loading-container {
  align-items: center;
  padding:10px;
  background-color:#FCFCFF;
}

.loading-animation {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
  aspect-ratio: 1 / 1;
}
