.button {
  margin-top: 10px;
  font-size: 14px;
  padding: 5px 15px;
  cursor: pointer;
  font-family: var(--font-larsseit-medium);
  border: 3px solid var(--color-brown);
}

@media (min-width:568px) {
  .button {
    font-size: 18px;
  }
}
