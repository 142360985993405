*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body, html {
  margin:0;
  padding:0;
  font-family: var(--font-larsseit-medium);
  color: var(--color-brown);
}

a {
   text-decoration:none;
   color: inherit;
 }

a:hover {
  color: var(--color-black);
}
