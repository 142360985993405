.app-content__container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  margin: 0 10px 10px 10px;
  height: calc(100dvh - var(--header-spacing-mobile));
  border: 3px solid var(--color-brown);
}

@media (min-width:568px) {
  .app-content__container {
    height: calc(100dvh - var(--header-spacing-desktop));
  }
}
