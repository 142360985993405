:root {
  --color-brown: #432D27;
  --color-light-brown: #dca286;
  --color-light-brown-hover: #c0876b;
  --color-signalgreen: #01d2b3;
  --color-blue: #5abebe;
  --color-blue-hover: #3e9e9e;
  --color-black: #000;
  --color-white: #fff;
  --font-larsseit-medium: 'Larsseit W03 Medium';
  --font-mono: 'Relative Mono';
  --header-height-mobile: 50px;
  --header-height-desktop: 85px;
  --header-spacing-mobile: 60px;
  --header-spacing-desktop: 95px;
}
