.app-header {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-content: center;
  height: var(--header-height-mobile);
  @media (min-width: 568px) {
    height: var(--header-height-desktop);
  }
}

.app-title {
  font-weight: normal;
  align-self: center;
  font-size: 20px;
  margin: 0;
  @media (min-width: 568px) {
    font-size: 26px;
  }
}

.app-logo {
  display: flex;
  padding: 10px 0;
  @media (min-width: 568px) {
    padding: 15px 0;
  }
}

.app-logo__link {
  display: flex;
}

.app-logo__image {
  align-self: center;
  max-width: 50px;
  @media (min-width: 568px) {
    max-width: 100%;
  }
}

.app-logo__text {
  font-family: var(--font-mono);
  text-align: right;
  align-self: center;
  margin: 0 20px 0 0;
  font-size: 10px;
  @media (min-width: 568px) {
    font-size: 1em;
  }
}


