
.error-zone {
  background-color: var(--color-light-brown);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 19px;
}

.error-message {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.retry-button {
  background: var(--color-light-brown);
  border-color: var(--color-brown);
  color: var(--color-brown);
}

.retry-button:hover {
  background: var(--color-brown);
  color: var(--color-light-brown);
}
