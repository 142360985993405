
.dropzone {
  flex: 1;
  display: inline-block;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--color-blue);
}

.drop-container__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  text-align: center;
}

@media (min-width:568px) {
  .drop-container__label  {
    font-size: 24px;
  }
}

.drop-container__label .camera {
  width: 35px;
}

@media (min-width:568px) {
  .drop-container__label .camera {
    width: 50px;
    height: 60px;
  }
}

.drop-container input[type="file"] {
  display: none;
}
