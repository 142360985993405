/* Override react-confirm-alert */

.react-confirm-alert-body {
  font-family: var(--font-larsseit-medium);
  border-radius: 0;
  color: var(--color-brown);
  border: 3px solid var(--color-brown);
  width: calc(100% - 20px);
  max-width: 400px;
  margin: 0 10px;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size:18px;
  font-weight:normal;
  line-height:1.1;
}

@media (min-width:568px) {
  .react-confirm-alert-body > h1 {
    font-size: 21px;
  }
}

.react-confirm-alert-button-group > button {
  margin-top: 10px;
  background: none;
  border: 3px solid var(--color-brown);
  border-radius: 0;
  font-size: 14px;
  color:var(--color-brown);
  cursor: pointer;
  font-family: var(--font-larsseit-medium);
  min-width:85px;
  transition: color 200ms ease-in, background-color 200ms ease-in, border-color 200ms ease-in;
}

@media (min-width:568px) {
  .react-confirm-alert-button-group > button {
    font-size: 18px;
  }

}

.react-confirm-alert-button-group > .yes-btn {
  background: var(--color-brown);
  color:var(--color-white);
}

.react-confirm-alert-button-group > .yes-btn:hover {
  background: var(--color-signalgreen);
  color:var(--color-white);
}

.react-confirm-alert-button-group > button:hover {
  border-color: var(--color-signalgreen);
  color: var(--color-signalgreen);
}
