.app-content__container.success-container {
  justify-content: center;
  border: none;
}

.image-container {
  position: relative;
  align-self: center;
  max-width: 500px;
  max-height: calc(100vh - var(--header-spacing-desktop));
  border: 3px solid var(--color-brown);
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-image {
  display: block;
  max-width: 100%;
  max-height: calc(100vh - var(--header-spacing-desktop) - 6px);
}


.reload-overlay {
  display: none;
  width: auto;
  height: auto;
  position: absolute;
  padding: 30px;
  border: 3px solid var(--color-brown);
}

.image-container:hover .reload-overlay {
  display: block;
  background-color: var(--color-white);
}

.reload-overlay .reload-icon {
  max-width: 40px;
  margin: 0 auto;

  @media (min-width:568px) {
    max-width: 80px;
  }
}

.reload-button {
  background: var(--color-brown);
  border-color: var(--color-brown);
  color: var(--color-white);
}

.reload-button:hover {
  background-color: var(--color-signalgreen);
  border-color: var(--color-signalgreen);
}

.image-overlay:hover .reload-button {
  display: inline-block;
}
